export class Notification{
    constructor(obj) {
        this.id = obj.id ? obj.id : null;
        this.type = obj.type ? obj.type : "";
        this.name = obj.name ? obj.name : "";
        this.project = obj.project ? obj.project : ""
        this.description = obj.description ? obj.description : "";
        this.date = obj.date ? obj.date : new Date()
    }

    //getters
    getId() { return this.id }
    getType() { return this.type }
    getName() { return this.name }
    getDescription() { return this.description }
    
    //setters
    setId(id) { this.id = id }
    setType(type) { this.type = type }
    setName(name) { this.name = name }
    setDescription(description) { this.description = description }
}