import { defineStore } from "pinia";
import { ref } from "vue";

export const useNotificationStore = defineStore("notification-store", () => {
  const isNewNotification = ref(false);
  const notification = ref([])
  const setNewNotificationToTrue = (value) => {
    isNewNotification.value = value;
  };
  const addNotification = (value) => {
    notification.value.unshift(value);
  };
  return { isNewNotification, notification, addNotification, setNewNotificationToTrue };
});