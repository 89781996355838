/**
 * Module representing a collection of project-related functions.
 * @module ProjectAPI
 * @version 0.0.1
 */

import apiClient from "./common/apiClient";
import errorHandler from "./common/errorHandler";

export default {

  /**
   * Retrieves a list of projects.
   * @async
   * @function getProjects
   * @returns {Promise} A promise that resolves to the list of projects.
   * @throws {Error} If an error occurs during the API request.
   */
  async getProjects() {
    try {
      const response = await apiClient.get("/project");
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  /**
   * Retrieves a specific project by its ID.
   * @async
   * @function getProject
   * @param {number} id - The ID of the project to retrieve.
   * @returns {Promise} A promise that resolves to the project data.
   * @throws {Error} If an error occurs during the API request.
   */
  async getProject(id) {
    try {
      const response = await apiClient.get(`/project/${id}`);
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  /**
   * Creates a new project.
   * @async
   * @function createProject
   * @param {object} project - The project object to create.
   * @returns {Promise} A promise that resolves to the created project data.
   * @throws {Error} If an error occurs during the API request.
   */
  async createProject(project) {
    try {
      const response = await apiClient.post("/project", project);
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  /**
   * Updates an existing project.
   * @async
   * @function updateProject
   * @param {object} project - The updated project object.
   * @returns {Promise} A promise that resolves to the updated project data.
   * @throws {Error} If an error occurs during the API request.
   */
  async updateProject(project) {
    try {
      const response = await apiClient.put('/project', project);
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  /**
   * Deletes a project by its ID.
   * @async
   * @function deleteProject
   * @param {number} id - The ID of the project to delete.
   * @returns {Promise} A promise that resolves to the deleted project data.
   * @throws {Error} If an error occurs during the API request.
   */
  async deleteProject(id) {
    try {
      const response = await apiClient.delete(`/project/${id}`);
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
};
