import axios from "axios";

// lazy instantiation
let apiClient = null;
/**
 * Retrieves the API client instance, lazily instantiating it if necessary.
 *
 * @returns {import("axios").AxiosInstance} The API client instance.
 * @version 0.0.1
 */
function getApiClient() {
  const env = useRuntimeConfig()
  if (!apiClient) {
    apiClient = axios.create({
      baseURL: env.public.apiBaseUrl,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  return apiClient;
}

export default getApiClient();